import API from '@/libs/axios'

class UtilitiesService {
  static getNotifications() {
    return API({
      url: 'notifications',
    })
  }

  static allNotificationsRead(data) {
    return API({
      url: 'mark-all-notificacion-read',
      method: 'post',
      data,
    })
  }

  // mark notification as read
  static markNotificationRead(data) {
    return API({
      url: 'mark-notification-read',
      method: 'post',
      data,
    })
  }
}

export default UtilitiesService
