<template>
  <!-- form -->
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(changePassword)">
      <!-- current password -->
      <b-form-group label="Contraseña actual" label-for="password">
        <ValidationProvider
          name="Contraseña actual"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input
            id="password"
            v-model="data.password"
            name="password"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- new password -->
      <b-form-group label="Nueva contraseña" label-for="new_password">
        <ValidationProvider
          name="Nueva contraseña"
          rules="required"
          v-slot="{ errors }"
          vid="new_password"
        >
          <b-form-input
            id="new_password"
            v-model="data.new_password"
            name="new_password"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- retry new password -->
      <b-form-group
        label="Repite nueva contraseña"
        label-for="retry_new_password"
      >
        <ValidationProvider
          name="Repite nueva contraseña"
          v-slot="{ errors }"
          rules="required|confirmed:new_password"
        >
          <b-form-input
            type="password"
            id="retry_new_password"
            v-model="data.retry_new_password"
            name="retry_new_password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- submit button -->
      <div class="text-center">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary">
            <feather-icon icon="SaveIcon" size="16" /> Actualizar
          </b-button>
        </b-overlay>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
//Services
import UserService from '@/services/user.service'
//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, confirmed } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Bootsrap vue
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
  BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormSelect,
    BOverlay
  },
  data() {
    return {
      required,
      min,
      confirmed,
      data: {
        password: '',
        new_password: '',
        retry_new_password: '',
      },
      overlay: false
    }
  },
  methods: {
    async changePassword() {
      try {
        this.overlay = true
        const { data: res } = await UserService.changePassword(this.data)

        if (res.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('executeChangePassword')
        } else {
          this.$swal({
            title: res.data.message,
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.overlay = false

      } catch (error) {
        this.overlay = false
        this.$swal({
          title: 'Ha ocurrido un error interno',
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    }
  }
}
</script>
