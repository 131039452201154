<template>
  <div>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="32"
        style="color: gray !important"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <b-badge pill variant="light-primary">
          {{ notificationsUnread }}
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :key="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.id" @click="goToDetail(notification)">
        <b-media>
          <template #aside v-if="!notification.is_read">
            <b-avatar size="32" variant="danger">
              <feather-icon icon="BellIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">
            {{ dateFormat(notification.created_at, 'DD MMMM YYYY, h:m') }}
          </small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-if="notificationsUnread > 0">
      <b-overlay
        :show="loadingbtn"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-100"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="link"
          block
          @click="allNotificationsRead()"
        >
          Marcar todas como leidas
        </b-button>
      </b-overlay>
    </li>
  </b-nav-item-dropdown>
    <b-sidebar no-header v-model="showDetailModal" right backdrop shadow body-class="colorBackground"
    width="500px">
      <recharge-detail v-if="detailType === 'recharges'" :id="detailId" @close="closeDetailModal()" />
      <withdrawal-detail v-if="detailType === 'withdrawals'" :id="detailId" @close="closeDetailModal()" />
    </b-sidebar>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BOverlay,
  BSidebar
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import UtilitiesService from '@/services/utilities.service'
import moment from 'moment'
moment.locale('es')
import { mapState } from 'vuex'
import Echo from 'laravel-echo'
import RechargeDetail from '@/components/RechargeDetail'
import WithdrawalDetail from '@/components/WithdrawalDetail'

export default {
  components: {
    BOverlay,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BSidebar,
    RechargeDetail,
    WithdrawalDetail
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showDetailModal: false,
      detailId: null,
      detailType: 'recharges',
      loadingbtn: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      notifications: [],
    }
  },
  computed: {
    notificationsUnread() {
      return this.notifications.filter((i) => {
        return !i.is_read
      }).length
    },
  },
  methods: {
    // mark notification as read
    async markAsRead(notification) {
      try {
        if (!notification.is_read) {
          await UtilitiesService.markNotificationRead({
            notif_id: notification.id,
          })
          this.getNotifications()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async goToDetail(notification) {
      // if (notification.type === 'users') {
      //   this.$router.push(notification.url)
      //   return
      // }
      // this.markAsRead(notification)
      const url = new URL('https://admin.billeterap2p.com/'+notification.url)
      const urlParams = new URLSearchParams(url.search);
      const id = urlParams.get('id');

      if (id) {
        this.detailType = notification.type
        setTimeout(async () => {
          this.detailId = id
          this.showDetailModal = true
          await this.markAsRead(notification)
        }, 300)
      }
    },
    closeDetailModal() {
      this.showDetailModal = false
      this.detailId = null
    },
    async allNotificationsRead() {
      if (this.notifications.length) {
        this.loadingbtn = true
        const notif_ids = this.notifications.map((i) => {
          return i.id
        })
        await UtilitiesService.allNotificationsRead({
          notif_ids: notif_ids,
        })
        this.getNotifications()
        this.loadingbtn = false
      }
    },
    dateFormat(date, format = 'DD/MM/YYYY') {
      return moment(date).format(format)
    },
    async getNotifications() {
      const { data: res } = await UtilitiesService.getNotifications()
      this.notifications = res.data
    },
    handleNewNotification(data) {
      this.notifications.unshift(data)
      const audio = new Audio(require('../assets/sounds/notif.mp3'))
      audio.play()
    },
    echo(userId) {
      try {
        const echo = new Echo({
          broadcaster: 'socket.io',
          host: 'https://api.billeterap2p.com:6001',
          // host: 'http://localhost:6001',
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        })

        echo.channel(`user.${userId}`).listen('.admini', async (data) => {
          this.handleNewNotification(data)
          const routeName = this.$route.name

          if (routeName === 'transactions' || routeName === 'recharges' || routeName === 'withdrawals' || routeName === 'home') {
            // split query params url
            const url = new URL('https://admin.billeterap2p.com/'+data.url)
            const urlParamsNoti = new URLSearchParams(url.search)
            const tabNoti = urlParamsNoti.get('tab')

            const urlParams = new URLSearchParams(window.location.search)
            const tab = urlParams.get('tab')

            if (tab && tab === tabNoti) {
              if (data.type === 'recharges') {
                const rd = await this.$store.dispatch('recharges/getDetail', {
                  id: data.data.item_id,
                  no_set_info: true
                })
                this.$store.dispatch('recharges/updateItems', rd)
              } else if (data.type === 'withdrawals') {
                const wd = await this.$store.dispatch('withdrawals/getDetail', {
                  id: data.data.item_id,
                  no_set_info: true
                })
                this.$store.dispatch('withdrawals/updateItems', wd)
              }
            }

            this.$store.dispatch('transactions/getTransactionsInfo', {})

          }
        })

        echo.channel(`user.${userId}`).listen('.adminEvent', async (data) => {
          const routeName = this.$route.name
          // Actualizar data del dashboard de admin
          if (routeName === 'home' && (data.type === 'rechargeCompleted' || data.type === 'withdrawalCompleted')) {
            await this.$store.dispatch('dashboard/getDashboardInfo', {})
            await this.$store.dispatch('dashboard/getTransactionsDashboard', {
                dates: null,
                type: 'week',
            })
            await this.$store.dispatch('dashboard/getPaymentMethodCountsDashboard', {
                type: 'recharge',
                dates: null,
            })
          }

          // Actualizar data de log de actividades
          if (routeName === 'home' && data.type === 'refreshLog') {
            await this.$store.dispatch('activityLog/get', {
                currentPage: 1,
            })
          }
        })
      } catch (error) {
        console.log('error notifications', error)
      }
    },
  },
  computed: {
    ...mapState({
      user_state: (state) => state.auth.user,
    }),
    notificationsUnread() {
      return this.notifications.filter((i) => {
        return !i.is_read
      }).length
    },
  },
  mounted() {
    if (process.env.VUE_APP_ENV !== 'dev') this.echo(this.user_state.id)
    this.getNotifications()
  },
}
</script>

<style></style>
