import API from '@/libs/axios';

class UserService {
	static changePassword(data) {
		return API({
			url: 'users/change-password',
			method: 'post',
			data,
		});
	}
}

export default UserService;
