<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-dropdown no-caret id="dropdown-notifications" variant="link">
        <template #button-content>
          <feather-icon icon="BellIcon" size="1.5x" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          Notificaciones
        </b-dropdown-item>
      </b-dropdown> -->

      <notifications />

      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user ? user.name : '' }}
            </p>
            <span class="user-status">Admin</span>
          </div> -->
          <b-avatar size="40" variant="light-primary" badge
            :src="require('@/assets/images/avatars/user-placeholder.jpg')" class="badge-minimal"
            badge-variant="success" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="(showChangePasswordModal = true)">
          <span>Cambiar contraseña</span>
        </b-dropdown-item>
        <!-- 
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item @click="logout()" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-sidebar id="sidebar-right" v-model="showChangePasswordModal" title="Cambiar contraseña" right shadow>
      <div class="p-2">
        <change-password @executeChangePassword="showChangePasswordModal = false" />
      </div>
    </b-sidebar>

  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BDropdown,
  BSidebar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapState, mapActions } from 'vuex'
import Notifications from '@/components/Notifications.vue'
import ChangePassword from '@/components/ChangePassword.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BDropdown,
    // Navbar Components
    DarkToggler,
    Notifications,
    BSidebar,
    ChangePassword
  },

  data() {
    return {
      showChangePasswordModal: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      companies: (state) => state.auth.companies,
      activeCompany: (state) => state.auth.company,
    }),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  methods: {
    ...mapActions({
      changeActiveCompany: 'auth/changeActiveCompany',
    }),
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
}
</script>
<style scoped>
.active {
  background: red !important;
}
</style>
