export default [
	{
		title: 'Tablero',
		route: 'home',
		icon: 'AirplayIcon',
	},
	{
		title: 'Transacciones',
		permission: 'transactions',
		route: 'transactions',
		icon: 'ActivityIcon',
		// children: [
		// 	{
		// 		title: 'Recargas',
		// 		permission: 'recharges',
		// 		route: 'recharges',
		// 	},
		// 	{
		// 		title: 'Retiros',
		// 		permission: 'withdrawals',
		// 		route: 'withdrawals',
		// 	},
		// 	{
		// 		title: 'Envios de dinero',
		// 		permission: 'transactions',
		// 		route: 'transactions',
		// 	},
		// ],
	},
	{
		title: 'Usuarios',
		permission: 'users',
		route: 'users',
		icon: 'UsersIcon',
		children: [
			{
				title: 'Aplicación',
				permission: 'users-app',
				route: 'users-app',
			},
			{
				title: 'Administración',
				permission: 'users-admin',
				route: 'users-admin',
			},
		],
	},
	{
		title: 'Reportes',
		permission: 'reports',
		route: 'reports',
		icon: 'ActivityIcon',
		children: [
			{
				title: 'Reporte uno',
				permission: 'report-one',
				route: 'report-one',
			},
			{
				title: 'Reporte dos',
				permission: 'report-two',
				route: 'report-two',
			},
		],
	},
	{
		title: 'Administrador',
		permission: 'administrator',
		route: 'administrator',
		icon: 'SettingsIcon',
		children: [
			{
				title: 'Roles',
				permission: 'roles',
				route: 'roles',
			},
			{
				title: 'Métodos de pago',
				permission: 'payment-methods',
				route: 'recharge-methods',
			},
			{
				title: 'Preguntas frecuentes',
				permission: 'help-faq',
				route: 'help-faq',
			},
		],
	},
];
