var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('b-form-group',{attrs:{"label":"Contraseña actual","label-for":"password"}},[_c('ValidationProvider',{attrs:{"name":"Contraseña actual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","name":"password","type":"password"},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nueva contraseña","label-for":"new_password"}},[_c('ValidationProvider',{attrs:{"name":"Nueva contraseña","rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new_password","name":"new_password","type":"password"},model:{value:(_vm.data.new_password),callback:function ($$v) {_vm.$set(_vm.data, "new_password", $$v)},expression:"data.new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Repite nueva contraseña","label-for":"retry_new_password"}},[_c('ValidationProvider',{attrs:{"name":"Repite nueva contraseña","rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","id":"retry_new_password","name":"retry_new_password"},model:{value:(_vm.data.retry_new_password),callback:function ($$v) {_vm.$set(_vm.data, "retry_new_password", $$v)},expression:"data.retry_new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"16"}}),_vm._v(" Actualizar ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }